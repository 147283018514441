// import { WETH } from 'entities/token'
import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  // BLAST_SEPOLIA = 168587773
  BLAST = 81457
}

export const chainConfig = {
  // [ChainId.BLAST_SEPOLIA]: {
  //   chainId: ChainId.BLAST_SEPOLIA,
  //   symbol: `Blast_sepolia`,
  //   weth: `0x4200000000000000000000000000000000000023`,
  //   multicall: `0xa334eBbf8552913a31F53FBA625592207D820080`,
  //   factory: `0x3AeEf2efd5a91BCDBD33191815986B94aaA28Ce3`,
  //   router: `0xD63202b98D73bbAB41cc12bb9abC25d34a4fD944`,
  //   initCodeHash: `0x455ac41c00790de6181a9cd24921952f5bdc527c69d8d6c8518838ed27dd00ba`,
  //   etherscan: `https://testnet.blastscan.io`
  // },
  [ChainId.BLAST]: {
    chainId: ChainId.BLAST,
    symbol: `Blast`,
    weth: `0x4300000000000000000000000000000000000004`,
    multicall: `0x80bf7644cd23ebB16de2734A386772C5f827c13c`,
    factory: `0x3978ABB63A42b522Bd205757C6310B5F46932927`,
    router: `0x066FA6D2fF29c76eA6fec03497F9ECa4ab675e85`,
    initCodeHash: `0x455ac41c00790de6181a9cd24921952f5bdc527c69d8d6c8518838ed27dd00ba`,
    etherscan: `https://blastscan.io/`
  }
}

// the Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = process.env.REACT_APP_PUBLIC_HOST + '/tokens.json'

export type ChainIdType = ChainId.BLAST

// ---------------------------------------------- functions -----------------------------------------------------
export const ChainIdMap = Object.values(chainConfig).reduce((obj, item) => {
  obj[item.symbol] = item.chainId
  return obj
}, {} as Record<string, any>)

export const ChainIdMap2 = Object.values(chainConfig).reduce((obj, item) => {
  obj[item.chainId] = item.symbol
  return obj
}, {} as Record<ChainIdType, string>)

export const WETH_LIST = Object.values(chainConfig).map((item) => {
  return { chainId: item.chainId, address: item.weth}
})

export const MULTICALL_NETWORKS: { [chainId in ChainIdType]: string } = Object.values(chainConfig).reduce((obj, item) => {
  obj[item.chainId] = item.multicall
  return obj
}, {} as Record<ChainIdType, string>)

export const ETHERSCAN_PREFIXES = Object.values(chainConfig).reduce((obj, item) => {
  obj[item.chainId] = item.etherscan
  return obj
}, {} as Record<ChainIdType, string>)

export function EtherscanLink(chainId: ChainIdType, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = ETHERSCAN_PREFIXES[chainId]
  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function ChainIdList(): number[] {
  return Object.values(ChainIdMap)
}

export function V1FactoryList(): any {
  return ChainIdList().reduce((obj, item) => {
    obj[item] = ''
    return obj
  }, {} as Record<number, string>)
}

export function EmptyList(): any {
  return ChainIdList().reduce((obj, item) => {
    obj[item] = {}
    return obj
  }, {} as Record<number, any>)
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
